import { doc, onSnapshot } from 'firebase/firestore'
import * as React from 'react'
import { db } from 'services/firebase'

import LeakAddIcon from '@mui/icons-material/LeakAdd'

import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'

function Main() {
  const [binState, setBinState] = React.useState<string>('shooting')
  const [bottleType, setBottleType] = React.useState<string>('transparent')

  const [activeStep, setActiveStep] = React.useState(2)

  React.useEffect(() => {
    const infoRef = doc(db, 'trash', 'info')
    onSnapshot(infoRef, (doc) => {
      setBinState((doc.data() as Info)['state'])
      setBottleType((doc.data() as Info)['bottleType'])
    })
  }, [])

  const steps = [
    {
      label: '카메라 촬영',
      description: '주기적으로 카메라를 촬영하여 쓰레기통 속에 페트병이 투입되었는지 확인합니다.',
    },
    {
      label: 'AI 분석',
      description:
        'colab에서 실행 중인 웹 서버에 촬영한 이미지를 전송하고, AI를 활용해 분석합니다.',
    },
    {
      label: '병 발견 후 처리',
      description: '페트병을 발견하여 처리합니다.',
    },
  ]

  React.useEffect(() => {
    if (binState == 'shooting') setActiveStep(0)
    else if (binState == 'predicting') setActiveStep(1)
    else if (binState == 'actioning') setActiveStep(2)
  }, [binState])
  return (
    <Box p={2}>
      <h1>Trash bin status</h1>
      {/* <span>{binState}</span> */}
      <Box sx={{ maxWidth: 400 }}>
        <Stack direction='row'>
          <LeakAddIcon />
          <Typography>실시간 동기화 중</Typography>
        </Stack>
        <Stepper activeStep={activeStep} orientation='vertical'>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <Typography>
                  {step.description +
                    (index == 2
                      ? activeStep == 2
                        ? bottleType == 'transparent'
                          ? ' 투명 병이 발견되어 오른쪽으로 폐기합니다.'
                          : bottleType == 'colored'
                          ? ' 유색 병이 발견되어 왼쪽으로 폐기합니다.'
                          : bottleType == 'no_bottle'
                          ? ' 페트병이 없습니다. 작업을 속행합니다.'
                          : bottleType == 'labeled'
                          ? '라벨이 있는 병입니다. 라벨을 제거해주세요.'
                          : '정확도가 낮아 분류하지 않습니다. 작업을 속행합니다.'
                        : ''
                      : '')}
                </Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  )
}

export default Main
