// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyCwNwnDjCaOsZ4PXOUgLkIJj77ZiWlMsB0',
  authDomain: 'cbsh-trash-honjaboi.firebaseapp.com',
  projectId: 'cbsh-trash-honjaboi',
  storageBucket: 'cbsh-trash-honjaboi.appspot.com',
  messagingSenderId: '219925068306',
  appId: '1:219925068306:web:ff7cea9759e24c127d36cb',
}

export const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
